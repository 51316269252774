import { resumeUrl } from "../models/urls/resumeUrl"
import { githubUrl } from "../models/urls/githubUrl"
import { gitlabUrl } from "../models/urls/gitlabUrl"
import { linkedInUrl } from "../models/urls/linkedInUrl"

export type Detail = {
    href:string,
    icon:string,
    text:string
}

export type Details = Detail[]

export const externalLinkDetails:Details = [
    {
        text: "Resumé",
        icon: "fa fa-file-alt",
        href: resumeUrl
    },
    {
        text: "Github",
        icon: "fa fa-github",
        href: githubUrl
    },
    {
        text: "Gitlab",
        icon: "fab fa-gitlab",
        href: gitlabUrl
    },
    {
        text: "LinkedIn",
        icon: "fab fa-linkedin",
        href: linkedInUrl
    },
]
