type KeyValueElementProps = {
    keyText: string,
    valueText: string,
    wrapperClass?:string,
    keyClass?:string,
    valueClass?:string
}

export const KeyValueTextLine = 
    ({ keyText, valueText, wrapperClass = "", keyClass = "", valueClass = "" }: KeyValueElementProps) => 
    <div className={`split-box ${wrapperClass ? wrapperClass  : ""}`}>
        <div className={`left ${keyClass ? wrapperClass  : ""}`}>
            <p>{keyText}</p>
        </div>
        <div className={`right ${valueClass ? wrapperClass  : ""}`}>
            <p>{valueText}</p>
        </div>
    </div>
