import { Link } from "react-router-dom"
import { githubUrl } from "../../models/urls/githubUrl"
import { gitlabUrl } from "../../models/urls/gitlabUrl"
import { linkedInUrl } from "../../models/urls/linkedInUrl"
import { resumeUrl } from "../../models/urls/resumeUrl"
import { imagePath } from "../../utils/images/imagePath"

export const NavBar = () => 
    <nav className="navbar navbar-expand-md navbar-light">
        <Link to="/" className="navbar-brand mr-5" data-toggle="collapse" data-target=".navbar-collapse">
            <img src={imagePath("headshot-new-sm.png")} className="mr-3 avatar" />
                Patrick Metsch
        </Link>
        <button className="btn navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-bars my-1" aria-hidden="true"></i>
        </button>
        <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="d-md-none navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item">
                    <hr className="mt-0" />
                </li>
                <li className="nav-item">
                    <a className="nav-link pointer text-primary" href="mailto:ptmetsch@gmail.com">
                        <i className="fas fa-envelope-open mr-2" aria-hidden="true"></i>
                        ptmetsch@gmail.com
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="tel:+4407247507">
                        <i className="fas fa-phone mr-2" aria-hidden="true"></i>
                        +1 440.724.7507
                    </a>
                </li>
                <li className="nav-item">
                    <hr className="mt-2" />
                </li>
                <li className="nav-item">
                    <a className="nav-link text-primary" href={linkedInUrl}>
                        <i className="fab fa-linkedin mr-2" aria-hidden="true"></i>
                        LinkedIn
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href={githubUrl}>
                        <i className="fa fa-github mr-2" aria-hidden="true"></i>
                        Github
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href={gitlabUrl}>
                        <i className="fab fa-gitlab mr-2" aria-hidden="true"></i>
                        Gitlab
                    </a>
                </li>
                <li className="nav-item">
                    <hr className="mt-2" />
                </li>
                <li className="nav-item">
                    <Link to="/portfolio" className="nav-link text-primary" data-toggle="collapse" data-target=".navbar-collapse">
                        <i className="fa fa-images mr-2" aria-hidden="true"></i>
                        Portfolio
                    </Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-primary" href={resumeUrl}>
                        <i className="fa fa-file-alt mr-2" aria-hidden="true"></i>
                        Resumé
                    </a>
                </li>
            </ul>
            <ul className="d-none d-lg-inline-flex nav navbar-nav">
                <li className="nav-item dropdown mx-3">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-0">
                            <i className="fas fa-search mr-2" aria-hidden="true"></i>
                            Find Me
                        </span>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href={githubUrl} target="_blank">
                            <i className="fa fa-github mr-2" aria-hidden="true"></i>
                            Github
                        </a>
                        <a className="dropdown-item" href={gitlabUrl} target="_blank">
                            <i className="fab fa-gitlab mr-2" aria-hidden="true"></i>
                            Gitlab
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item text-primary" href={linkedInUrl} target="_blank">
                            <i className="fab fa-linkedin mr-2" aria-hidden="true"></i>
                            LinkedIn
                        </a>
                    </div>
                </li>
                <li className="nav-item dropdown mx-3">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-0">
                            <i className="fas fa-medal mr-2" aria-hidden="true"></i>
                            My Work
                        </span>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link to="/portfolio" className="dropdown-item">
                            <i className="fa fa-images mr-2" aria-hidden="true"></i>
                            Portfolio
                        </Link>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item text-primary" href={resumeUrl} target="_blank">
                            <i className="fa fa-file-alt mr-2" aria-hidden="true"></i>
                            Resumé
                        </a>
                    </div>
                </li>
            </ul>
            <ul className="d-none d-lg-inline-flex nav navbar-nav ml-auto">
                <li className="nav-item dropdown mx-2">
                    <a className="nav-link dropdown-toggle text-white btn primary-btn" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2">
                            <i className="fas fa-comments mr-2" aria-hidden="true"></i>
                            Contact
                        </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="tel:+4407247507">
                            <i className="fas fa-phone mr-2" aria-hidden="true"></i>
                            +1 440.724.7507
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item text-primary pointer" href="mailto:ptmetsch@gmail.com">
                            <i className="fas fa-envelope-open mr-2" aria-hidden="true"></i>
                            ptmetsch@gmail.com
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    