
export const stringFromArray = 
(delimiter:string) =>
(arr:string[]) =>
    arr.join(delimiter)

export const paddedDashDelimitedStringFromArray =
    (arr:string[]) =>
        stringFromArray(" - ")(arr)

export const commaDelimitedStringFromArray = 
    (arr:string[]) =>
        stringFromArray(", ")(arr)