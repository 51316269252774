import { Details } from "../../models/externalLinkDetails"
import { IconButton } from "../button/IconButton"

type HomePagePresenterContentProps = {
    header: string
    content: string
    links: Details
}

export const HomePagePresenterContent = 
    ({header, content, links}:HomePagePresenterContentProps) =>
            <div className="presenter-content-container px-0 pb-0 flex">
                <div className="px-3">
                    <h3>{header}</h3>
                    <p>{content}</p>
                </div>
                <div className="link-container">
                    {
                        links.map(({href, icon, text}) => 
                            <IconButton 
                                href={href}
                                iconClassName={icon}
                                text={text}
                                buttonClassName="secondary-btn"
                            />
                        )
                    }
                </div>
            </div>
