import { externalLinkDetails, Details } from "../models/externalLinkDetails"

type HomePageContent = {
    heading: string,
    imgSrc: string,
    content: string
    links: Details
}

export const homePagePresenterContents:HomePageContent[] = 
    [
        {
            heading: "Relentless Agile Delivery",
            imgSrc: "team.png",
            content: `
                I believe in keeping an open line of communication within my own team, product managers, and most importantly
                the users. By auto-deploying to distinct reviewable environments, I give all concerned parties real-time
                insight into story progression. This allows for a continuous dialog, facilitating negotiation 
                and iteration throughout the entire lifecycle of a feature. 
            `,
            links: externalLinkDetails.filter(({text}) => text === "Resumé")
        },
        {
            heading: "Clean. Tested. Efficient.",
            imgSrc: "programmer.png",
            content: `
                I love what I do. I take pride in, not only the UI/UX experience I provide, but the quality of the
                code that facilitates it. I utilize React and Vue to design modular and reusable components,
                Typescript to catch errors quickly, functional programming principles to deliver predictability through 
                immutability, high-coverage unit and integration tests, and analytics tools to gauge the user experience.
            `,
            links: externalLinkDetails.filter(({text}) => text === "Gitlab" || text === "Github")
        },
        {
            heading: "The Full Stack",
            imgSrc: "server.png",
            content: `
                The user experience is about more than just the interface. In order for a web app to be performant, it must
                deliver content quickly, reliably, and efficiently. That's why I employ state-of-the-art SRE and devops solutions - 
                Kubernetes, Docker, CI/CD, TDD - in my work; to provide continuous delivery of quality software services.
            `,
            links: externalLinkDetails.filter(({text}) => text === "LinkedIn")
        },
    ]
