import { Fragment } from "react"
import { portfolioPageHeroContent } from "../../models/hero/portfolioPageHeroContent"
import { portfolioPagePresenterContents } from "../../models/portfolioPagePresenterContents"
import { imagePath } from "../../utils/images/imagePath"
import { Hero } from "../page/Hero"
import { Presenter } from "../presenter/Presenter"
import { PortfolioPresenterContent } from "./PortfolioPresenterContent"

export const PortfolioPage = 
    () =>
    <Fragment>
        <Hero {...portfolioPageHeroContent} />
        <main>
            <div className="presenter-container">
                {
                    portfolioPagePresenterContents.map(({heading, subheading, imgSrc, id, title, timeline, description, skills}) => 
                        <Presenter
                            imgSrc={imagePath(imgSrc)}
                            children={
                                <PortfolioPresenterContent 
                                    heading={heading}
                                    subheading={subheading}
                                    identifier={id}
                                    title={title}
                                    timeline={timeline}
                                    description={description}
                                    skills={skills}
                                />
                            }
                        />
                    )
                }
            </div>
        </main>
    </Fragment>