import { HeroProps } from "../../ui/page/Hero"

export const portfolioPageHeroContent:HeroProps = {
    heading: "Portfolio",
    subheading: "Patrick Metsch - Full Stack Engineer",
    paragraph: `
        I see every project as an opportunity to refine my skills and learn new technologies. By continually expanding my knowledge,
        I not only grow as an individual - I bring more to the table. 
    `
}
