type PresenterProps = {
    children:JSX.Element,
    imgSrc:string,
    flashy?:boolean
}

export const Presenter = 
    ({imgSrc, children, flashy = false}:PresenterProps) => 
    <div className={`presenter ${flashy ? "flashy" : ""}`}>
        <img className="preview-img" src={imgSrc} />
        {children}
    </div>

