import { imagePath } from "../../utils/images/imagePath"
import { IconButton } from "../button/IconButton"
import { externalLinkDetails } from "../../models/externalLinkDetails"

export type HeroProps = {
    heading: string,
    subheading:string,
    paragraph:string
}

export const Hero = 
    ({heading, subheading, paragraph}:HeroProps) => 
    <div className="jumbotron">
        <div className="row">
            <div className="col-lg-6 col-sm-12 left-or-top">
                <div className="container">
                    <h2>{heading}</h2>
                    <h3>{subheading}</h3>
                    <p>{paragraph}</p>
                    <div>
                        <div className="btn-group d-none d-lg-block d-xl-block" role="group">
                            {
                                externalLinkDetails.map(({href, icon, text}, index) => 
                                    <IconButton 
                                        href={href}
                                        buttonClassName={index === 0 ? "primary-btn" : "secondary-btn"}
                                        iconClassName={icon}
                                        text={text}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div> 
            <div className="col-lg-6 col-sm-12 right-or-bottom">
                <img className="headshot avatar rim" src={imagePath("headshot-new-lg.png")} />
            </div>
        </div>
    </div>