import { imagePath } from "../../utils/images/imagePath"
import { Presenter } from "../presenter/Presenter"
import { HomePagePresenterContent } from "./HomePagePresenterContent"
import { homePagePresenterContents } from "../../models/homePagePresenterContents"
import { Fragment } from "react"
import { Hero } from "../page/Hero"
import { homePageHeroContent } from "../../models/hero/homePageHeroContent"

export const HomePage = 
    () => 
        <Fragment>
            <Hero {...homePageHeroContent} />
            <main>
                <div className="presenter-container">
                    {homePagePresenterContents.map(({heading, imgSrc, content, links}) =>
                        <Presenter 
                        imgSrc={imagePath(imgSrc)} 
                        children={
                            <HomePagePresenterContent 
                                header={heading}
                                content={content} 
                                links={links} />
                            } />
                        )}
                </div>
            </main>
        </Fragment>