import { Link } from "react-router-dom"

type IconButtonProps = {
    href: string,
    buttonClassName:string,
    iconClassName:string,
    text:string
}

const isInternal = (href:string) => href.charAt(0) === "/"

export const IconButton = 
    ({href, iconClassName, text, buttonClassName}:IconButtonProps) => 
        isInternal(href)
            ? (
                <Link 
                    to={href} 
                    className={`btn ${buttonClassName}`}>
                        <i className={`${iconClassName} mr-2`} aria-hidden="true"></i>{text}
                </Link>
            )
            : (
                <a 
                    href={href} 
                    target="_blank" 
                    className={`btn ${buttonClassName}`}>
                        <i className={`${iconClassName} mr-2`} aria-hidden="true"></i>{text}
                </a>
            )


