import { Fragment } from "react";
import { externalLinkDetails } from "../../models/externalLinkDetails";
import { imagePath } from "../../utils/images/imagePath";
import { IconButton } from "../button/IconButton"

export const PageFooter = () => 
<footer>
    <div className="container-fluid">
        <div className="row">
            <div className="img-container col-12">
                <img className="avatar" src={imagePath("headshot-new-sm.png")} />
            </div>
            <div className="link-container col-12">
                <Fragment>
                    {
                        externalLinkDetails.map(
                            ({text, href, icon}) => 
                            <IconButton 
                                href={href}
                                buttonClassName={""}
                                iconClassName={icon}
                                text={text}
                            />
                        )
                    }
                    <div className="copyright-container">
                        <p>Copyright &copy; Patrick Metsch, 2021</p>
                        <p>All Rights Reserved</p>
                    </div>
                </Fragment>
            </div>
        </div>
    </div>
</footer>