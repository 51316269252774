import { BrowserRouter, Route, Switch } from "react-router-dom" 
import { HomePage } from "../home/HomePage"
// import { SkillsPage } from "../skills/SkillsPage"
// import { SkillPage} from "../skills/SkillPage"
import { PageHeader } from "../page/PageHeader"
import { PageFooter } from "../page/PageFooter"
import { NotFoundPage } from "../notFound/NotFoundPage"
import { RedirectToNotFound } from "../notFound/RedirectToNotFound"
import { PortfolioPage } from "../portfolio/PortfolioPage"

export const Routes = () => 
    <BrowserRouter>
        <div>
            <PageHeader />
            <Switch>
                <Route exact={true} path="/" component={HomePage} />
                {/* <Route path="/skills" component={SkillsPage} />
                <Route path="/skill/:id" component={SkillPage} /> */}
                <Route path="/portfolio" component={PortfolioPage} />
                <Route path="/notFound" component={NotFoundPage} />
                <Route exact={true}>
                    <RedirectToNotFound />
                </Route>
            </Switch>
            <PageFooter />
        </div>
    </BrowserRouter>