import { addUidToIterable } from "../utils/iterable/addUidToIterable"
import { SansId } from "../utils/types/SansId"

type PortfolioPageContent = {
    id: number,
    imgSrc: string,
    heading: string,
    subheading: string,
    title:string,
    timeline: [string, string],
    skills:string[]
    description: string[]
    
}

export const portfolioPagePresenterContentsWithoutId:SansId<PortfolioPageContent>[] = [
    {
        imgSrc: "sourceability.png",
        heading: "Sourceability",
        subheading: "Electronics Supply Chain Solutions",
        title: "Full Stack Engineer",
        timeline: ["2020", "Present"],
        skills: ["Kubernetes", "Docker", "NodeJS", "TSX", "VueJS", "RxJS", "Boostrap", "Sass"],
        description: 
        [
            `
                Work alongside a remote team of full stack developers, coordinating with product managers,
                UI/UX designers, and other stakeholders to iteratively develop and deploy several responsive,
                accessible, and SEO-optimized public websites as well as internal sales tools. Deliver REST APIs 
                to serve our team's product, and the business as a whole.
            `,
            `
                Product was successfully used - within 3 months of inception - to successfully sell a 3 year Analytics 
                API contract to an international multibillion dollar company, as well as several other contracts.
            `
        ]
    },
    {
        imgSrc: "heartpoints.png",
        heading: "Heartpoints.org",
        subheading: "The Currency of Good",
        title: "Front End Developer",
        timeline: ["2019", "Present"],
        skills: ["Docker", "NodeJS", "Express", "ReactJS", "MaterialUI", "Firebase"],
        description: [
            `
                Work directly with the founder to experiment and iteratively develop a platform for the "currency of good",
                a currency that is created through acts of volunteerism with non profits or individual people, and then 
                spreads when these same volunteers donate it to others in order to empower organizations whose missions they care about.
            `,
            `
                Was given the opportunity to learn new technologies, design patterns, and paradigms to be applied to the application,
                as well as shared with other passionate junior developers through remote pairing sessions.
            `
        ],
    },
    {
        imgSrc: "rosetta.png",
        heading: "Rosetta Marketing Group",
        subheading: "Marketing & Advertising Agency",
        title: "Front End Developer",
        timeline: ["2008", "2009"],
        skills: ["Actionscript3", "MXML", "Adobe Flex", "Photoshop"],
        description: [
            `
                Participate in the user experience definition process, and develop rich, interactive web applications based on those definitions.
                Utilize Actionscript3 in the Adobe Flex environment to develop and deliver rich, interactive web applications for various
                big-name organizations.
            `,

            `
                Follow the SDLC from concept inception, iterative development, testing, to deployment alongside a robust team of engineers,
                designers, and product managers to deliver high quality applications quickly.
            `
        ]
    },
    {
        imgSrc: "sullivan.png",
        heading: "Sullivan Remodels",
        subheading: "Cleveland Kitchen Remodeling",
        title: "Architect / Designer / Webmaster",
        timeline: ["2019", "Present"],
        skills: ["Express", "HTML5", "CSS3", "NodeJS", "Photoshop"],
        description: [
            `
                Design, develop, deploy, and maintain an informational website for a local home remodeler. Led in the development of 
                creative designs, UI assets, infrastructure, and content to deliver an SEO-optimized site to serve as the front line
                for customer engagement.
            `,
            `
                Implement and maintain a social media presence for the client, monitor and analyze traffic and performance with
                Google Analytics, and deliver detailed reports and prospective marketing strategies.
            `
        ],
    },
    {
        imgSrc: "cleEntertainers.png",
        heading: "Cleveland Entertainers",
        subheading: "Point. Click. Party.",
        title: "Webmaster / Support Technician",
        timeline: ["2010", "2015"],
        skills: ["HTML5", "CSS3", "Photoshop"],
        description: [
            `
                Undertook all responsibilities as they pertained to regular maintenance of the existing website, including updating 
                information and assets as well as introducing new content. 
            `,
            `
                Managed on-site deployments of hardware and software solutions and services during scheduled parties and events.
            `
        ],
    },
    {
        imgSrc: "pmetsch.png",
        heading: "Patmetsch.com",
        subheading: "It's This Site!",
        title: "Architect / Designer / Webmaster",
        timeline: ["2021", "Present"],
        skills: ["ReactJS", "Typescript", "Sass", "Kubernetes", "Docker", "Skaffold", "NodeJS", "Photoshop"],
        description: [
            `
                Having my own site allows me to experiment with new technology. This latest site autodeploys with Skaffold to GKE via a Gitlab 
                CI/CD pipeline as part of a monorepo I helped create alongside some colleagues who share my passion for time saving tech.
            `,
            `
                I couldn't look at myself in the mirror if I didn't make my own website!
            `,
        ],
    },
]

export const portfolioPagePresenterContents = addUidToIterable(portfolioPagePresenterContentsWithoutId)