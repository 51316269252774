import { paddedDashDelimitedStringFromArray, commaDelimitedStringFromArray } from "../../utils/iterable/stringFromArray"
import { KeyValueTextLine } from "../presenter/KeyValueTextLine"
import { ParagraphObscure } from "../presenter/ParagraphObscure"

type PortfolioPresenterContentProps = {
    identifier: number,
    heading: string,
    subheading: string,
    title:string,
    timeline: [string, string],
    skills:string[]
    description: string[]
}

export const PortfolioPresenterContent = 
    ({heading, subheading, identifier, title, timeline, description, skills}:PortfolioPresenterContentProps) => 
    <div className="presenter-content-container block">
        <div className="header-subheader-container">
            <h3>{heading}</h3>
            <small>{subheading}</small>
        </div>
        <KeyValueTextLine keyText="Title" valueText={title} wrapperClass="bold" />
        <KeyValueTextLine keyText="Timeline" valueText={paddedDashDelimitedStringFromArray(timeline)} />
        <KeyValueTextLine keyText="Skills" valueText={commaDelimitedStringFromArray(skills)} />
        <ParagraphObscure 
            paras={description}
            identifier={identifier}
        />
    </div>