import { removeClass } from "../../utils/dom/removeClass"
import { addClass } from "../../utils/dom/addClass"
import { el } from "../../utils/dom/el"
import { Fragment } from "react"

type ParagraphObscureProps = {
    paras: string[],
    identifier: string | number
}

export const ParagraphObscure = 
    ({paras, identifier}:ParagraphObscureProps) => {
        const defaultParagraph = paras[0]
        const extraParagraphs = 
            paras
                .filter((_, index ) => index !== 0)
                .map((para, index) => 
                    ({
                        para,
                        id: `extra-paragraph-${identifier}-${index}`
                    })
                )

        const hasExtraText = extraParagraphs.length > 0

        const defaultParagraphId = `default-paragraph-${identifier}`
        const showMoreId = `show-more-${identifier}`

        const showExtraParagraphs = 
            () => 
                extraParagraphs
                    .forEach(({id}) => 
                        removeClass(el(id))("hidden")
                    )

        const hideShowMore = () => addClass(el(showMoreId))("hidden")

        const updateView = () => {
            showExtraParagraphs()
            hideShowMore()
        }

        return (
            <div className="paragraph-container text-obscure">
                <p id={defaultParagraphId}>
                    {defaultParagraph}
                </p>
                {
                    hasExtraText && (
                        <Fragment>
                            <p id={showMoreId} className="show-more" onClick={updateView}>Show More</p>
                            {
                                extraParagraphs.map(
                                    ({para, id}) =>
                                        <p id={id} className="hidden">
                                            {para}
                                        </p>
                                    )
                            }
                        </Fragment>
                    )
                }
            </div>
        )
    }