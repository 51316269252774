import { HeroProps } from "../../ui/page/Hero"

export const homePageHeroContent:HeroProps = {
    heading: "Patrick Metsch",
    subheading: "Full Stack Engineer",
    paragraph:`
        I am a Cleveland based practitioner of the full stack development mindset, working across front end, back end, data, 
        infrastructure, and monitoring to deliver on team goals and the company mission. My drive to deliver a harmonious user 
        experience makes me an asset to any team.
    `
}

