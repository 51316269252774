import React from 'react';
import ReactDOM from 'react-dom';
import { Routes } from "./ui/routing/Routes"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'
import "./staticContent/css/main.css"

ReactDOM.render(
    <Routes />,
  document.getElementById('root')
);
